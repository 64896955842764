export const navLinks = [
    { id: 1, label: 'خانه', href: '#home' },
    { id: 2, label: 'محصولات', href: '#products' },
    { id: 3, label: 'درباره ما', href: '#about-us' },
    { id: 4, label: 'تماس با ما', href: '#contact-us' },
]
export const statistics = [
    { id: 1, label: 'فروش', value: 130 },
    { id: 2, label: 'دانلود', value: 165 },
    { id: 3, label: 'محصولات', value: 103 }
]
export const slideImg = [
    { id: 1, src: './img/1p.png' },
    { id: 2, src: './img/2p.png' },
    { id: 3, src: './img/3p.png' },
]
export const products = [
    { id: 1, name: 'لورم ایپسوم', price: '1200000', imgUrl: './img/1p.png' },
    { id: 2, name: 'لورم ایپسوم فارسی', price: '200000', imgUrl: './img/2p.png' },
    { id: 3, name: 'تولید لورم', price: '800000', imgUrl: './img/3p.png' },
    { id: 4, name: 'آموزش Lorm فارسی ', price: '0', imgUrl: './img/5p.png' },
]
export const cards = [
    { id: 1, title: 'لورم ایپسوم', iconUrl: './img/icon1.png', description: 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است.' },
    { id: 2, title: 'لورم متن ساختکی', iconUrl: './img/icon2.png', description: 'طراحان سایت هنگام طراحی قالب سایت معمولا با این موضوع رو برو هستند که محتوای اصلی صفحات آماده نیست.' },
    { id: 3, title: 'لورم فارسی', iconUrl: './img/icon1.png', description: 'چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد.' },
    { id: 4, title: 'متن سادگی لورم', iconUrl: './img/icon2.png', description: 'متنی ساختگی و بدون معنی است که برای امتحان فونت و یا پر کردن فضا در یک طراحی گرافیکی و یا صنعت چاپ استفاده میشود.' },
]
export const managers = [
    {id: 1, name: 'لورم فارسی', imgUrl: './img/avatar1.png', bio: 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است'},
    {id: 2, name: 'لورم ایپسوم', imgUrl: './img/avatar2.jpg', bio: 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز'}
]